import "bootstrap";

import * as Sentry from "@sentry/vue";

let sentry_dsn = document.documentElement.getAttribute("data-sentry-dsn");
let sentry_release = document.documentElement.getAttribute(
    "data-sentry-release",
);
let sentry_environment = document.documentElement.getAttribute(
    "data-sentry-environment",
);
Sentry.init({
    dsn: sentry_dsn,
    release: sentry_release,
    environment: sentry_environment,
});
if (USER_ID !== undefined) {
    Sentry.setUser({
        email: USER_EMAIL,
        id: USER_ID,
    });
}

Sentry.withScope(function (scope) {
    require(["./vue_app"]);
});

// export jQuery to global scope; this is neede for django-session-security
const jquery = require("jquery");
global.$ = jquery;
global.jQuery = global.$;
